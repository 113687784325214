import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      {rooms.map((item, index) => (
        <Room key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  margin: 7.5rem auto;
  padding: 0 11.667vw;

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem auto;
  }
`
