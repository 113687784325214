import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { Move, User } from 'react-feather'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  languageCode: string
  pax?: string
  size?: string
  title?: string
  URL: string
}

export const Room = memo(function Room({
  image,
  languageCode,
  pax,
  size,
  title,
  URL,
}: Props) {
  return (
    <Container to={URL}>
      <FadeInUp>
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
        <Wrapper>
          {title ? <Title>{title}</Title> : null}
          {pax || size ? (
            <Info dial={4} row wrap>
              {pax ? (
                <Pax dial={4} row>
                  <User />
                  {pax}
                </Pax>
              ) : null}
              {size ? (
                <Size dial={4} row>
                  <Move />
                  {size}
                </Size>
              ) : null}
            </Info>
          ) : null}
        </Wrapper>
        <CTA label={useVocabularyData('take-a-seat', languageCode)} noActions />
      </FadeInUp>
    </Container>
  )
})

const Container = styled(Link)`
  > div {
    height: 57.8vh;
    max-height: 520px;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-top: 0.125rem;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    > div {
      height: auto;
      padding-bottom: 120%;
    }
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 2.875rem 6.458vw 3.875rem 3.194vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 1.5rem 1.9375rem;
    text-align: center;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 1.75rem;
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 1.875rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-right: 0.75rem;
  }

  @media (max-width: 1023px) {
    justify-content: center;
    margin-top: 1.25rem;
  }
`

const Pax = styled(FlexBox)`
  margin-right: 2.375rem;

  @media (max-width: 1023px) {
    margin-right: 1.75rem;
  }
`

const Size = styled(FlexBox)``

const CTA = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;

  @media (max-width: 1023px) {
    left: 0;
  }
`
