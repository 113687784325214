import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  source?: string
  text?: string
}

export const Review = memo(function Review({ source, text }: Props) {
  if (!text) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
      },
    },
    hidden: {
      opacity: 0,
      scale: 0,
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="63.901"
        height="40"
        viewBox="0 0 63.901 40"
      >
        <path
          d="M5.14,36.636A12.866,12.866,0,0,0,17.282,47.5c-1.491,5.539-5.113,7.74-9.515,9.018a.515.515,0,0,0-.355.568l.71,4.4s.071.355.639.284c15.2-1.7,25.492-13.279,23.575-27.339-1.775-9.728-9.373-13.492-16.4-12.5A12.948,12.948,0,0,0,5.14,36.636ZM53.639,47.5c-1.42,5.539-5.184,7.74-9.515,9.018a.515.515,0,0,0-.355.568l.639,4.4s.071.355.639.284c15.267-1.7,25.421-13.279,23.646-27.339-1.846-9.728-9.515-13.492-16.474-12.5A12.845,12.845,0,0,0,41.5,36.636,12.673,12.673,0,0,0,53.639,47.5Z"
          transform="translate(-4.993 -21.782)"
        />
      </svg>
      <Text>{text}</Text>
      {source ? <Source>{source}</Source> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  max-width: 294px;
  margin: auto;
  position: relative;
  z-index: 2;

  svg {
    width: auto;
    height: 2.5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight2};
    margin-bottom: 2.5rem;
  }

  @media (max-width: 1023px) {
    max-width: none;
    text-align: center;

    svg {
      display: none;
    }
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
`

const Source = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin-top: 1.25rem;
  text-transform: uppercase;
`
